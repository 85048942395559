import styled from 'styled-components';

export const ParallaxWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(30,35,40,0.65);
`

export const ParallaxText = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.color.primary};
  /* background-color: rgba(30,35,40,0.65); */
  box-shadow: 5px 5px 15px ${props => props.theme.color.dark};
  padding: 10px;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-size: ${props => props.theme.fontSize.lg};
  }
`