import React from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { 
  ServiceBox, 
  ServiceBoxWrapper, 
  ServiceImg, 
  ServiceText, 
  ServicesWrapper 
} from './ServicesElements'
import tire from "../../assets/images/tire.png"
import car from "../../assets/images/car.png"
import vus from "../../assets/images/vus.png"

const ServicesPage = () => {
  const { scrollY } = useScroll()

  // Define different speeds for each service box
  const y1 = useTransform(scrollY, [0, 1000], [0, -100]) // Slow
  const y2 = useTransform(scrollY, [0, 1000], [0, -200]) // Medium speed
  const y3 = useTransform(scrollY, [0, 1000], [0, -150]) // Fast

  return (
    <ServicesWrapper>
      <motion.div style={{ y: y1 }}>
        <ServiceBox>
          <ServiceText ml>ACHAT & VENTE DE PNEUS</ServiceText>
          <ServiceImg src={tire} />
        </ServiceBox>
      </motion.div>

      <ServiceBoxWrapper>
        <motion.div style={{ y: y2 }}>
          <ServiceBox>
            <ServiceText ml>CHANGEMENT DE PNEUS</ServiceText>
            <br />
            <ServiceText ml>50$</ServiceText>
            <ServiceText>VOITURE, BERLINE, PETIT VUS</ServiceText>
            <ServiceImg src={car} />
            <br />
            <ServiceText ml>60$</ServiceText>
            <ServiceText>VUS, PICKUP, CAMIONNETTE</ServiceText>
            <ServiceImg src={vus} />
          </ServiceBox>
        </motion.div>
      </ServiceBoxWrapper>

      <motion.div style={{ y: y3 }}>
        <ServiceBox>
          <ServiceText ml>DÉPANNAGE</ServiceText>
          <ServiceText>RÉPARATION DE PNEUS CREVÉS</ServiceText>
          <ServiceText>INSTALLATION PNEUS DE SECOURS</ServiceText>
        </ServiceBox>
      </motion.div>
    </ServicesWrapper>
  )
}

export default ServicesPage
