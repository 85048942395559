import styled from 'styled-components';

export const ContactWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.color.primary};
  padding: 50px 20px;
  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`

export const Sections = styled.div`
  width: 45vw;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: 1280px) {
    width: 100vw;
  }
`

export const ContactTitle = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.ml};
  line-height: 2rem;
  color: ${props => props.theme.color.dark};
  margin: 10px 50px;
  text-align: center;

`
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;


`

export const NamesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const InputsWrapper = styled.input`
  background-color: ${props => props.theme.color.secondary};
`

export const Input = styled.input.attrs(props => ({
  type: "text",
}))`
  color: ${props => props.theme.color.dark};
  background-color: ${props => props.theme.color.secondary};
  font-size: ${props => props.theme.fontSize.sm};
  min-width: 250px;
  border: none;
  font-family: ${props => props.theme.font.primary};
  line-height: 30px;
  margin: 10px;
  padding: 0 5px;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    font-size: ${props => props.theme.fontSize.xs};
  }
`;

export const MessageInput = styled.textarea.attrs(props => ({
  type: "text",
}))`
  color: ${props => props.theme.color.dark};
  background-color: ${props => props.theme.color.secondary};
  font-size: ${props => props.theme.fontSize.sm};
  min-width: 520px;
  min-height: 200px;
  border: solid 1px ${props => props.theme.color.secondary};
  font-family: ${props => props.theme.font.primary};
  line-height: 25px;
  margin: 5px 10px;
  padding: 0 5px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 10px;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    min-width: 90vw;
    font-size: ${props => props.theme.fontSize.xs};
  }
`;

export const SubmitInput = styled.input.attrs(props => ({
  type: "submit",
}))`
  color: ${props => props.theme.color.primary};
  background: ${props => props.theme.color.dark};
  font-size: ${props => props.theme.fontSize.md};
  border: none;
  font-family: ${props => props.theme.font.primary};
  margin: 20px 10px;
  padding: 15px 40px;
  border-radius: 50px;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    font-size: ${props => props.theme.fontSize.sm};
  }
`;

export const OrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
`

export const OrText = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.theme.color.dark};
`

export const ContactText = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.theme.color.dark};
  text-align: center;
  width: 100%;
  margin: 40px 0 20px 0;
`

export const OrLine = styled.img`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.theme.color.dark};
`

export const ContactImg = styled.img`
  min-width: 100px;
  height: auto;
  margin: 0 20px;
`

export const Phone = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => props.theme.color.dark};
  text-align: center;
  width: 100%;
`

export const PinImg = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 30px;
  @media screen and (max-width: 1280px) {
   margin-top: 100px;
  }
`

export const Region = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => props.theme.color.dark};
  text-align: center;
  width: 100%;
`

export const RegionSub = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.theme.color.dark};
  text-align: center;
  width: 100%;
  margin: 10px 0;
  margin-bottom: 30px;
`

export const AllRegions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 45vw;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Regions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 30px 20px;
  @media screen and (max-width: 1280px) {
   margin: 0;
  }
`

export const RegionText = styled.div`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => props.theme.fontSize.sm};
  color: ${props => props.theme.color.dark};
  margin: 7px 0;
`

