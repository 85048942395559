import styled from 'styled-components';
import { Link as Links } from 'react-scroll'
import { motion } from "framer-motion";

export const HomeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  background-color: ${props => props.theme.color.dark};
`

export const LogoWrapper = styled(motion.img)`
  width: 40vw;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 80vw;
  }
`

export const MotionWrapper = styled(motion.div)`
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.dark};
  width: 30vw;
  border-radius: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: bold;

  @media screen and (max-width: 900px) {
    width: 80vw;
  }
`

export const ButtonWrapper = styled(Links)`
  cursor: pointer;


  &:hover {
    background-color: ${props => props.theme.color.secondary};
  }

  @media screen and (max-width: 900px) {
    width: 80vw;
    font-size: 0.9em;
  }
`
