import React, { useRef } from 'react'
import logo from "../../assets/images/logo.png"
import { ButtonWrapper, HomeWrapper, LogoWrapper, MotionWrapper } from './HomeElements'
import { motion, useScroll, useTransform } from "framer-motion";

const HomePage = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll(); // Track full page scroll

  const opacityLogo = useTransform(scrollYProgress, [0.05, 0.1], [1, 0]); // Fade out main logo
  const opacityButton = useTransform(scrollYProgress, [0.15, 0.2], [1, 0]); // Fade out main logo
  return (
    <HomeWrapper ref={ref}>
      <LogoWrapper
        src={logo}
        initial={{ opacity: 0 }}  // Fade-in when page loads
        animate={{ opacity: 1 }}  // Fade-in effect
        transition={{ duration: 2, delay: 0.7 }} // 1s fade-in with delay
        style={{ opacity: opacityLogo }} // Fade out on scroll
      />
      <MotionWrapper
              initial={{ opacity: 0 }}  // Fade-in when page loads
              animate={{ opacity: 1 }}  // Fade-in effect
              transition={{ duration: 2, delay: 1 }} // 1s fade-in with delay
              style={{ opacity: opacityButton }} // Fade out on scroll
              >
        <ButtonWrapper
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
        >Prenez rendez-vous maintenant!</ButtonWrapper>
      </MotionWrapper>
    </HomeWrapper>
  )
}

export default HomePage